/* @import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700;800&display=swap"); */

html,
body,
#root {
  height: 100%;
  margin: 0;
  overflow-x: hidden;
}
#portal {
  z-index: 999;
  position: relative;
}
body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.datePickerBox {
  position: absolute;
  top: 71px;
  height: auto;
  width: auto;
  border: 1px solid black;
  padding: 5px;
  z-index: 999;
  background: #fff;
  /* right:0px; */
}

.App {
  height: 100%;
  display: flex;
  flex-direction: column;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.footerCaption_2r5qf {
  position: absolute;
  top: 0;
}

.MuiToggleButtonGroup-root {
  position: absolute;
  top: 10px;
  margin-left: 5px;
  background-color: #fff;
}

.drawerActive {
  background-color: #fff;
  position: relative;
}
.drawerActive span {
  color: #2f2f5d !important;
}

.drawerActive::before {
  top: 0;
  left: -5px;
  width: 5px;
  height: 100%;
  content: "";
  position: absolute;
  background: #f4bc73;
}

.drawerActive svg {
  fill: #2f2f5d !important;
}

.selectActive .MuiPaper-root.MuiDrawer-paper {
  overflow-y: inherit !important;
}

.MuiPaper-root.MuiDrawer-paper {
  scrollbar-color: #919191 #e2e2e2;
  scrollbar-width: thin;
  -ms-overflow-style: none;
}
.MuiPaper-root.MuiDrawer-paper::-webkit-scrollbar {
  width: 6px;
}

.MuiPaper-root.MuiDrawer-paper::-webkit-scrollbar-track {
  background-color: #e2e2e2;
}
.MuiPaper-root.MuiDrawer-paper:hover::-webkit-scrollbar-thumb {
  width: 6px;
  background-color: #919191;
  border-radius: 8px;
}

.statusdeactive {
  background: rgba(255, 98, 32, 0.07) !important;
}
.statusdeactive select {
  color: #ff6220 !important;
}

#datePickerContainer,
#claimDatePickerContainer,
#lossDatePickerContainer {
  display: none;
}

::-webkit-scrollbar {
  height: 6px;
  width: 6px;
}
::-webkit-scrollbar-track {
  background-color: #e2e2e2;
}
::-webkit-scrollbar-thumb {
  height: 6px;
  background-color: #919191;
  border-radius: 8px;
}

div#simple-popover-hover {
  background: #0000004f;
  z-index: 9 !important;
}
div#simple-popover-hover .MuiPaper-root {
  right: 50px;
  left: auto;
}
